import React from 'react';
import { useHistory } from 'react-router';
import { API_Image_Product, API_Image_Brand } from '../utils/API';

interface Data {
    id: string,
    name_product: string,
    image_product: string,
    image_brand: string
};

const Product = (props : Data) => {

    const history = useHistory();

    return (
        <div onClick={() => history.push('/product/' + props.id)} className="card-product">
            <div className="overlay"></div>
            <img src={API_Image_Brand(props.image_brand)} className="card-brand-product" alt="NoImage" />
            <div className="card-image-product">
                <img src={API_Image_Product(props.image_product)} alt="NoImage" />
            </div>
            <h5>{props.name_product}</h5>
        </div>
    );
}

export default Product;