/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  FaBuilding,
  FaGlobeAsia,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import { useParams } from "react-router";
import Navbar from "../../component/navbar";
import {
  API_Get_Brand_ID,
  API_Get_Search,
  API_GET_Work_All_Brand,
  API_Image_Brand,
} from "../../utils/API";
import Swal from "sweetalert2";
import Footer from "../../component/footer";
import Product from "../../component/product";

const Brand_id = () => {
  interface BrandStruct {
    id_brand: string;
    name_brand: string;
    image_brand: string;
    tel: string;
    website: string;
    email: string;
    address: string;
  }

  type SearchStruct = {
      work: string
      category: string
      kind: string
  };

  type WorkStruct = {
      id_work: string
      name_work: string
      TbCategorys: Array<CategoryStruct>
  };

  type CategoryStruct = {
      id_category: string
      name_category: string
      TbKinds: Array<KindStruct>
  };

  type KindStruct = {
      id_kind: string
      name_kind: string
  };

  
  type ProductStruct = {
    id_product: string;
    name_product: string;
    image_product: string;
    image_brand: string;
  };

  const [Data, setData] = useState<BrandStruct>({
    id_brand: "",
    name_brand: "",
    image_brand: "",
    tel: "",
    website: "",
    email: "",
    address: "",
  });


  const [Products, setProducts] = useState<Array<ProductStruct>>([]);

  const [Page, setPage] = useState<number>(1);

  const [Total, setTotal] = useState<number>(1);

  const [Loading, setLoading] = useState<boolean>(false);

  const [Search, setSearch] = useState<SearchStruct>({
    work: '',
    category: '',
    kind: '',
  });

  const [Work, setWork] = useState<Array<WorkStruct>>([]);

  const param = useParams<{
    id: string;
  }>();

  const Get_Brand = () => {
    API_Get_Brand_ID(param.id)
      .then((res) => {
        setData(res.data[0]);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Get_WorkAll = () => {
      API_GET_Work_All_Brand(param.id).then(res => setWork(res.data)).catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  }

  const onChangeData = (event : ChangeEvent<HTMLSelectElement>) => {
      switch (event.target.id) {
        case "work":
          setSearch((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
            category: "",
            kind: "",
          }));
          break;
        case "category":
          setSearch((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
            kind: "",
          }));
          break;
        default:
          setSearch((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
          }));
          break;
      }
  }

  const Get_Product = () => {
    API_Get_Search(
      '',
      Search.work !== '' ? Work[parseInt(Search.work)].id_work : '',
      Search.category !== '' ? Work[parseInt(Search.category)].TbCategorys[parseInt(Search.category)].id_category : '',
      Search.kind !== '' ? Work[parseInt(Search.work)].TbCategorys[parseInt(Search.category)].TbKinds[parseInt(Search.kind)].id_kind : '',
      param.id,
      Page
    )
      .then((res) => {
        setProducts(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const onPage = (id: number) => {
    if (id > 0 && id <= Math.ceil(Total / 50)) {
      setPage(id);
    }
  };

  useEffect(() => {
    Get_Brand();
    Get_WorkAll();
  }, []);

  useEffect(() => {
    setLoading(true);
    const timeOutId = setTimeout(() => {
      Get_Product();
      setLoading(false);
      setProducts([]);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [Search, Page]);

  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "90px", minHeight: "100vh" }}
      >
        <div className="columns" style={{ gap: "10px" }}>
          <div className="column card is-3">
            <div className="card-content">
              <img
                src={API_Image_Brand(Data.image_brand)}
                width="100%"
                alt="NoImage"
              />
            </div>
          </div>
          <div className="column card">
            <div className="card-content">
              <div className="columns">
                <div className="column">
                  <h5 className="title is-5">
                    <FaBuilding /> {Data.name_brand}
                  </h5>
                </div>
                <div className="column">
                  <h5 className="title is-5">
                    <FaEnvelope /> {Data.email}
                  </h5>
                </div>
                <div className="column">
                  <h5 className="title is-5">
                    <FaPhoneAlt /> {Data.tel}
                  </h5>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h5 className="title is-5">
                    <FaGlobeAsia /> {Data.website}
                  </h5>
                </div>
                <div className="column">
                  <h5 className="title is-5">
                    <FaMapMarkerAlt /> {Data.address}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field-body m-2">
          <div className="field">
            <label className="work">หมวดงาน</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select onChange={onChangeData} value={Search.work} id="work">
                  <option value="">ทั้งหมด</option>
                  {
                      Work.map((item, index) => (
                          <option key={index} value={index}>{item.name_work}</option>
                      ))
                  }
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="category">ประเภท</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select onChange={onChangeData} disabled={Search.work === ''} value={Search.category} id="category">
                  <option value="">ทั้งหมด</option>
                  {
                      Search.work !== '' &&
                      Work[parseInt(Search.work)].TbCategorys.length > 0 &&
                      Work[parseInt(Search.work)].TbCategorys.map((item, index) => (
                          <option key={index} value={index}>{item.name_category}</option>
                      ))
                  }
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="kind">หมวดหมู่</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select onChange={onChangeData} disabled={Search.category === ''} value={Search.kind} id="kind">
                  <option value="">ทั้งหมด</option>
                  {
                      Search.category !== '' &&
                      Work[parseInt(Search.work)].TbCategorys[parseInt(Search.category)].TbKinds.length > 0 &&
                      Work[parseInt(Search.work)].TbCategorys[parseInt(Search.category)].TbKinds.map((item, index) => (
                          <option key={index} value={index}>{item.name_kind}</option>
                      ))
                  }
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="is-flex is-aglin-items-center is-justify-content-center is-flex-wrap-wrap mt-5"
          style={{ gap: "10px" }}
        >
          {Loading ? (
            <>
              {Array.from(Array(21), (_,index) => (
                <div key={index} className="card-product-loading"></div>
              ))}
            </>
          ) : (
            <>
              {Products.map((item, index) => (
                <Product
                  key={index}
                  id={item.id_product}
                  name_product={item.name_product}
                  image_product={item.image_product}
                  image_brand={item.image_brand}
                />
              ))}
            </>
          )}
        </div>

        <nav
          className="pagination is-centered is-rounded m-2 mt-5"
          role="navigation"
          aria-label="pagination"
        >
          <a
            onClick={() => onPage(Page - 1)}
            className="pagination-previous"
          >
            <FaAngleLeft />
          </a>
          <a onClick={() => onPage(Page + 1)} className="pagination-next">
            <FaAngleRight />
          </a>
          <ul className="pagination-list">
            {Array.from(Array(Math.ceil(Total / 50)), (_, i) => (
              <li key={i}>
                <a
                  onClick={() => onPage(i + 1)}
                  className={`pagination-link ${
                    i + 1 === Page ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${i + 1}`}
                >
                  {i + 1}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <Footer />
    </>
  );
};

export default Brand_id;
