import React, {
  useRef,
  KeyboardEvent,
  ChangeEvent,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router";
import Navbar from "../component/navbar";
import countrydata from "../assets/country.json";
import {
  FaUser,
  FaLock,
  FaAddressCard,
  FaTransgender,
  FaHardHat,
  FaPhone,
  FaGlobe,
} from "react-icons/fa";
import { API_GET_Country, API_Get_Job, API_Post_Register } from "../utils/API";
import Swal from "sweetalert2";

const Login = () => {
  interface JobStruct {
    id_job: string;
    name_job: string;
  }

  const history = useHistory();

  const Data = useRef({
    username: "" as string,
    password: "" as string,
    firstname: "" as string,
    lastname: "" as string,
    gender: "" as string,
    job: "" as string,
    tel: "" as string,
    country: "" as string,
    accept: false,
  });

  const [Error, setError] = useState({
    username: false,
    password: false,
    firstname: false,
    lastname: false,
    gender: false,
    job: false,
    tel: false,
    country: false,
    accept: false,
  });

  const [Job, setJob] = useState<Array<JobStruct>>([]);

  const [Loading, setLoading] = useState<boolean>(false);

  const ChangeData = (event: ChangeEvent<any>) => {
    let data = {
      ...Data.current,
      [event.target.id]: event.target.value,
    };
    Data.current = data;
  };

  const check_empty = (value: string, id: string) => {
    if (value !== "") {
      setError((preState) => ({ ...preState, [id]: false }));
      return true;
    } else {
      setError((preState) => ({ ...preState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  const CheckAccept = () => {
    if (Data.current.accept) {
      setError((preState) => ({ ...preState, accept: false }));
    } else {
      setError((preState) => ({ ...preState, accept: true }));
    }
    return Data.current.accept;
  };

  const onSubmitData = () => {
    if (
      check_empty(Data.current.username, "username") &&
      check_empty(Data.current.password, "password") &&
      check_empty(Data.current.firstname, "firstname") &&
      check_empty(Data.current.lastname, "lastname") &&
      check_empty(Data.current.gender, "gender") &&
      check_empty(Data.current.job, "job") &&
      check_empty(Data.current.tel, "tel") &&
      check_empty(Data.current.country, "country") &&
      CheckAccept()
    ) {
      setLoading(true);
      API_Post_Register(
        Data.current.username,
        Data.current.password,
        Data.current.firstname,
        Data.current.lastname,
        Data.current.gender,
        Data.current.job,
        Data.current.tel,
        Data.current.country,
      )
        .then((res) => {
          Swal.fire({
            icon: res.status ? "success" : "error",
            title: res.status ? "สำเร็จ" : "เกิดข้อผิดพลาด",
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            if (res.status) {
              history.push("/login");
            }
          });
          setLoading(false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
            timer: 5000,
            timerProgressBar: true,
          });
          setLoading(false);
        });
    }
  };

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    event.key === "Enter" && onSubmitData();
  };

  const Get_Country = (): void => {
    API_GET_Country()
      .then((res) => {
        Data.current.country = res;
        (document.getElementById("country") as HTMLInputElement).value =
          res;
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Get_Job = (): void => {
    API_Get_Job()
      .then((res) => {
        setJob(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  useEffect(() => {
    Get_Job();
    Get_Country();
  }, []);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "90px" }}>
        <div
          className="card mt-5 mb-5"
          style={{ maxWidth: "850px", margin: "0 auto" }}
        >
          <div className="card-content">
            <h2 className="title is-2 has-text-centered">สมัครสมาชิก</h2>
            <div className="field-body">
              <div className="field">
                <label htmlFor="username" className="label">
                  ชื่อผู้ใช้งาน
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <input
                    autoFocus
                    disabled={Loading}
                    onKeyPress={onEnter}
                    onChange={ChangeData}
                    id="username"
                    className={`input ${Error.username ? "is-danger" : ""}`}
                    type="text"
                    placeholder="อีเมลของท่าน"
                  />
                  <span className="icon is-small is-left">
                    <FaUser />
                  </span>
                </div>
                {Error.username && (
                  <p className="help is-danger">
                    กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง
                  </p>
                )}
              </div>
              <div className="field">
                <label htmlFor="password" className="label">
                  รหัสผ่าน
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <input
                    disabled={Loading}
                    onKeyPress={onEnter}
                    onChange={ChangeData}
                    id="password"
                    className={`input ${Error.password ? "is-danger" : ""}`}
                    type="password"
                    placeholder="รหัสผ่านของท่าน"
                  />
                  <span className="icon is-small is-left">
                    <FaLock />
                  </span>
                </div>
                {Error.password && (
                  <p className="help is-danger">กรุณากรอกรหัสผ่านให้ถูกต้อง</p>
                )}
              </div>
            </div>
            <div className="field-body">
              <div className="field mt-2">
                <label htmlFor="firstname" className="label">
                  ชื่อจริง
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <input
                    disabled={Loading}
                    onKeyPress={onEnter}
                    onChange={ChangeData}
                    id="firstname"
                    className={`input ${Error.firstname ? "is-danger" : ""}`}
                    type="text"
                    placeholder="ชื่อจริงของท่าน"
                  />
                  <span className="icon is-small is-left">
                    <FaAddressCard />
                  </span>
                </div>
                {Error.firstname && (
                  <p className="help is-danger">กรุณากรอกชื่อจริงให้ถูกต้อง</p>
                )}
              </div>
              <div className="field mt-2">
                <label htmlFor="lastname" className="label">
                  นามสกุล
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <input
                    disabled={Loading}
                    onKeyPress={onEnter}
                    onChange={ChangeData}
                    id="lastname"
                    className={`input ${Error.lastname ? "is-danger" : ""}`}
                    type="text"
                    placeholder="นามสกุลของท่าน"
                  />
                  <span className="icon is-small is-left">
                    <FaAddressCard />
                  </span>
                </div>
                {Error.lastname && (
                  <p className="help is-danger">กรุณากรอกนามสกุลให้ถูกต้อง</p>
                )}
              </div>
            </div>
            <div className="field-body">
              <div className="field mt-2" style={{width: '100%'}}>
                <label htmlFor="gender" className="label">
                  เพศ
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <div className="select is-fullwidth">
                    <select
                      defaultValue=""
                      disabled={Loading}
                      onChange={ChangeData}
                      id="gender"
                      className={`${Error.gender ? "is-danger" : ""}`}
                    >
                      <option disabled value="">
                        เลือกเพศ
                      </option>
                      <option value="0">ชาย</option>
                      <option value="1">หญิง</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <FaTransgender />
                  </span>
                </div>
                {Error.gender && (
                  <p className="help is-danger">กรุณาเลือกเพศให้ถูกต้อง</p>
                )}
              </div>
              <div className="field mt-2" style={{width: '100%'}}>
                <label htmlFor="job" className="label">
                  อาชีพ
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <div className="select is-fullwidth">
                    <select
                      defaultValue=""
                      disabled={Loading}
                      onChange={ChangeData}
                      id="job"
                      className={`${Error.job ? "is-danger" : ""}`}
                    >
                      <option value="">เลือกอาชีพ</option>
                      {Job.map((item, index) => (
                        <option key={index} value={item.id_job}>
                          {item.name_job}
                        </option>
                      ))}
                      <option value="อื่นๆ">อื่นๆ</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <FaHardHat />
                  </span>
                </div>
                {Error.job && (
                  <p className="help is-danger">กรุณาเลือกเพศให้ถูกต้อง</p>
                )}
              </div>
            </div>
            <div className="field-body">
              <div className="field mt-2" style={{width: '100%'}}>
                <label htmlFor="tel" className="label">
                  เบอร์โทร
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <input
                    disabled={Loading}
                    onChange={ChangeData}
                    onKeyPress={onEnter}
                    id="tel"
                    type="text"
                    className={`input ${Error.tel ? "is-danger" : ""}`}
                    placeholder="เบอร์โทรของท่าน"
                  />
                  <span className="icon is-small is-left">
                    <FaPhone />
                  </span>
                </div>
                {Error.tel && (
                  <p className="help is-danger">กรุณาเลือกเบอร์โทรให้ถูกต้อง</p>
                )}
              </div>
              <div className="field mt-2" style={{width: '100%'}}>
                <label htmlFor="country" className="label">
                  ประเทศ
                </label>
                <div
                  className={`control has-icons-left ${
                    Loading ? "is-loading" : ""
                  }`}
                >
                  <div className="select is-fullwidth">
                    <select
                      defaultValue=""
                      disabled={Loading}
                      onChange={ChangeData}
                      id="country"
                      className={`${Error.country ? "is-danger" : ""}`}
                    >
                      <option value="">เลือกประเทศ</option>
                      {countrydata.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                      <option value="อื่นๆ">อื่นๆ</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <FaGlobe />
                  </span>
                </div>
                {Error.job && (
                  <p className="help is-danger">กรุณาเลือกประเทศให้ถูกต้อง</p>
                )}
              </div>
            </div>

            <div className="field mt-4">
              <div
                className={`control ${Error.accept ? "has-text-danger" : ""}`}
              >
                <label className="checkbox">
                  <input
                    onChange={() =>
                      (Data.current.accept = !Data.current.accept)
                    }
                    type="checkbox"
                  />{" "}
                  ข้อตกลงในการใช้งาน{" "}
                  <a href="/policy" target="_blank">
                    ความเป็นส่วนตัว
                  </a>
                </label>
              </div>
            </div>

            <button
              disabled={Loading}
              onClick={() => onSubmitData()}
              className={`button is-primary is-fullwidth mb-2 ${
                Loading ? "is-loading" : ""
              }`}
            >
              สมัครสมาชิก
            </button>
            <button
              onClick={() => history.push("/login")}
              className="button is-primary is-outlined is-fullwidth mb-2"
            >
              กลับ
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
