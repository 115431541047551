import React, { useRef, useState } from "react";
import Step1 from "../component/forgot/step1";
import Step2 from "../component/forgot/step2";
import Step3 from "../component/forgot/step3";
import Navbar from "../component/navbar";

const Forgot = () => {
  const [Status, setStatus] = useState<number>(0);

  const Data = useRef({
    email: "" as String,
    code: "" as String,
  });

  const ChangeEmail = (mail: String) => {
    Data.current.email = mail;
  };

  const ChangeCode = (code: String) => {
    Data.current.code = code;
  };

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "90px" }}>
        {Status === 0 && <Step1 email={ChangeEmail} back={setStatus} />}
        {Status === 1 && (
          <Step2
            code={ChangeCode}
            email={Data.current.email}
            back={setStatus}
          />
        )}
        {Status === 2 && <Step3 back={setStatus} item={Data.current} />}
      </div>
    </>
  );
};

export default Forgot;
