import React from "react";
import logo from '../assets/logo_footer.png';
import { FaEnvelope, FaFacebookSquare, FaGlobe, FaLine, FaPhone, FaYoutube } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="mt-5 pt-5 pb-3" style={{ backgroundColor: "rgb(37, 44, 64)"}}>
      <div className="container has-text-centered ">
        <div className="columns">
          <div className="column m-2 is-4">
            <h5 className="title is-5 has-text-white">Contact Us</h5>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaEnvelope />
              <span className="ml-3">contact@mail.com</span>
            </h6>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaPhone />
              <span className="ml-3">086-709-9433 หรือ 082-626-2762</span>
            </h6>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaLine />
              <span className="ml-3">@sketchuphome</span>
            </h6>
          </div>
          <div className="column m-2 is-4 p-4">
            <h5 className="title is-5 has-text-white">Follow Us</h5>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaFacebookSquare />
              <span className="ml-3">บ้าน SketchUp</span>
            </h6>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaYoutube />
              <span className="ml-3">บ้าน SketchUp</span>
            </h6>
            <h6 className="title is-6 has-text-white has-text-left">
	      <FaGlobe />
              <span className="ml-3">www.sketchupthai.com</span>
            </h6>
          </div>
          <div className="column m-2 is-4 p-4">
            <figure className="image is-2by1">
              <img src={logo} alt="NoImage" />
            </figure>
          </div>
        </div>
        <div className="has-text-centered has-text-white">
          <p>
            สงวนลิขสิทธิ์ © 2564 - ข้อมูลและเนื้อหาทั้งหมด - บริษัท
            บ้านสเกตช์อัพ จำกัด
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
