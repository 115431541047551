import React, { ChangeEvent, useRef, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { API_Post_Forgot } from '../../utils/API';
import Swal from 'sweetalert2';

interface Struct {
    back: (id : number) => void
    email: (email : String) => void
}

const Step1 = (props : Struct) => {

    const history = useHistory();

    const data = useRef('');
    
    const [Error, setError] = useState(false);
    const [Loading, setLoading] = useState(false);

    const onsubmit = () : void => {
        if (
            check_empty(data.current, "username")
        ) {
            setLoading(true);
            API_Post_Forgot(data.current).then(res => {
                Swal.fire({
                    icon: res.status ? 'success' : 'error',
                    title: res.status ? 'สำเร็จ' : 'เกิดข้อผิดพลาด',
                    text: res.message,
                    timer: 5000,
                    timerProgressBar: true,
                }).then(() => {
                    if (res.status) {
                        props.email(data.current);
                        props.back(1);
                    }
                });
                setLoading(false);
            }).catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                    timer: 5000,
                    timerProgressBar: true,
                });
                setLoading(false);
            });
        }
    }

    const check_empty = (value : string, id : string) : boolean => {
        if (value !== '') {
            setError(false);
            return true;
        } else {
            setError(true);
            document.getElementById(id)?.focus();
            return false;
        }
    }

    const ChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        data.current = event.target.value;
    }

    return (
            <div className="card mt-5" style={{width: '350px', margin: '0 auto'}}>
                <div className="card-content">
                    <h2 className="title is-2 has-text-centered">ลืมรหัสผ่าน</h2>
                    <div className="field">
                        <label htmlFor="username" className="label">ชื่อผู้ใช้งาน</label>
                        <div className={`control has-icons-left ${Loading ? 'is-loading' : ''}`}>
                            <input onChange={ChangeData} disabled={Loading} id="username" className={`input ${Error ? 'is-danger' : ''}`} type="text" placeholder="อีเมลของท่าน" />
                            <span className="icon is-small is-left">
                                <FaUser />
                            </span>
                        </div>
                        {
                            Error && <p className="help is-danger">กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง</p>
                        }
                    </div>

                    <button disabled={Loading} onClick={() => onsubmit()} className="button is-primary is-fullwidth mt-3 mb-2">ส่งโค้ดยืนยัน</button>
                    <button disabled={Loading} onClick={() => history.push('/login')} className="button is-primary is-outlined is-fullwidth mb-2">กลับ</button>
                    
                </div>
            </div>
    )
}

export default Step1;