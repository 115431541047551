import React, { ChangeEvent, useRef, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { API_Post_Rest } from '../../utils/API';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

interface Struct {
    item: {
        code: String,
        email: String,
    },
    back: (id : number) => void,
}

const Step1 = (props : Struct) => {

    const hisotry = useHistory();

    const [Error, setError] = useState({
        passwordnew: false,
        confirmpassword: false
    });
    const [Loading, setLoading] = useState(false);

    const Data = useRef({
        passwordnew: '',
        confirmpassword: '',
    });

    const ChangeData = (event : ChangeEvent<HTMLInputElement>) => {
        let data = {
            ...Data.current,
            [event.target.id]: event.target.value
        }
        Data.current = data;
    }

    const onsubmit = () : void => {
        if (
            check_empty(Data.current.passwordnew, 'passwordnew') &&
            check_empty(Data.current.confirmpassword, 'confirmpassword') &&
            check_password()
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'คำเตือน',
                text: 'คุณต้องการที่จะเปลื่ยนรหัสผ่านใช่หรือไม่?',
                confirmButtonText: 'ใช่',
                showConfirmButton: true,
                cancelButtonText: 'ไม่',
                showCancelButton: true
            }).then(result => {
                if (result.value) {
                    setLoading(true);
                    API_Post_Rest(props.item.email, props.item.code, Data.current.confirmpassword).then(res => {
                        Swal.fire({
                            icon: res.status ? 'success' : 'error',
                            title: res.status ? 'สำเร็จ' : 'เกิดข้อผิดพลาด',
                            text: res.message,
                            timer: 5000,
                            timerProgressBar: true,
                        }).then(() => {
                            if (res.status) {
                                hisotry.push('/login');
                            }
                        });
                    }).catch(() => {
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    });
                }
            })
        }
    }

    const check_empty = (value : string, id : string) : boolean => {
        if (value !== '') {
            setError(preState => ({
                ...preState,
                [id]: false
            }));
            return true;
        } else {
            setError(preState => ({
                ...preState,
                [id]: true
            }));
            document.getElementById(id)?.focus();
            return false;
        }
    }

    const check_password = () => {
        if (Data.current.passwordnew === Data.current.confirmpassword) {
            return true;
        } else {
            setError(preState => ({
                ...preState,
                'confirmpassword': true
            }));
            document.getElementById('confirmpassword')?.focus();
            return false;
        }
    }

    return (
            <div className="card mt-5" style={{width: '350px', margin: '0 auto'}}>
                <div className="card-content">
                    <h2 className="title is-2 has-text-centered">เปลื่ยนรหัสผ่าน</h2>
                    <div className="field">
                        <label htmlFor="passwordnew" className="label">รหัสผ่านใหม่</label>
                        <div className={`control has-icons-left ${Loading ? 'is-loading' : ''}`}>
                            <input disabled={Loading} onChange={ChangeData} id="passwordnew" className={`input ${Error.passwordnew ? 'is-danger' : ''}`} type="text" placeholder="รหัสผ่านใหม่ จำนวน 10 หลักขึ้นไป" />
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>
                        </div>
                        {
                            Error.passwordnew && <p className="help is-danger">กรุณากรอกรหัสผ่านใหม่ให้ถูกต้อง</p>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="confirmpassword" className="label">ยืนยันรหัสผ่าน</label>
                        <div className={`control has-icons-left ${Loading ? 'is-loading' : ''}`}>
                            <input disabled={Loading} id="confirmpassword" onChange={ChangeData} className={`input ${Error.confirmpassword ? 'is-danger' : ''}`} type="text" placeholder="ยืนยันรหัสผ่าน" />
                            <span className="icon is-small is-left">
                                <FaLock />
                            </span>
                        </div>
                        {
                            Error.confirmpassword && <p className="help is-danger">กรุณากรอกยืนยันรหัสผ่านให้ถูกต้อง</p>
                        }
                    </div>

                    <button disabled={Loading} onClick={() => onsubmit()} className="button is-primary is-fullwidth mt-3 mb-2">ยืนยัน</button>
                    <button disabled={Loading} onClick={() => props.back(1)} className="button is-primary is-outlined is-fullwidth mb-2">กลับ</button>
                    
                </div>
            </div>
    )
}

export default Step1;