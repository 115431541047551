/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { API_Post_Me } from '../utils/API';
import { AuthContext } from '../utils/Auth-Context';
import Brand from './brand';
import Brand_id from './brand/brand_id';
import Extension from './Extension';
import Forgot from './Forgot';
import Home from './Home';
import Login from './Login';
import OAuth from './Oauth';
import Policy from './Policy';
import Search_product from './product';
import Product_id from './product/product_id';
import Profile from './profile';
import Profile_id from './profile/profile_id';
import Register from './Register';

const Loading = () => {

    const context = useContext(AuthContext);

    useEffect(() => {
        if (localStorage.hasOwnProperty('token') && !context.Auth[0]) {
            API_Post_Me().then(res => {
                context.Auth[1](true);
                context.Data[1](res.data);
            }).catch(() => {
                context.Auth[1](false);
                localStorage.removeItem('token');
            });
        }
    }, []);

    return (
        <>
            <Route exact path="/" component={Home} />
            <Route exact path="/oauth" component={OAuth} />
            <Route exact path="/policy" component={Policy} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/product" component={Search_product} />
            <Route exact path="/product/:id" component={Product_id} />
            <Route exact path="/brand" component={Brand} />
            <Route exact path="/brand/:id" component={Brand_id} />
            <Route exact path="/profile" component={Profile_id} />
            <Route exact path="/profile/:id" component={Profile} />
            <Route exact path="/extension" component={Extension} />
        </>
    );
}

export default Loading;