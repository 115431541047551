import React from 'react';

const Profile_id = () => {
    return (
        <div>
            Product ID
        </div>
    );
}

export default Profile_id;