import React from 'react';
import Navbar from '../component/navbar';
import { FaTimesCircle } from 'react-icons/fa';

const Error404 = () => {
    return (
        <>
            <Navbar />
            <div className="is-flex is-justify-content-center is-align-items-center" style={{height: '90vh'}}>
                <div className="has-text-centered">
                    <FaTimesCircle className="has-text-danger" style={{fontSize: '100px'}} />
                    <h1 className="title is-3 mt-5 has-text-danger">ไม่พบหน้าที่ท่านต้องการ</h1>
                </div>
            </div>
        </>
    );
}

export default Error404;