/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import { FaAngleRight } from "react-icons/fa";
import CardBrand from "../component/card_brand";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { API_GET_Brand_Popular, API_GET_Product_New_Limit } from "../utils/API";
import Swal from "sweetalert2";
import ProductCard from "../component/product";

const Home = () => {
  type BrandData = {
    id_brand: string;
    name_brand: string;
    image_brand: string;
  };

  type ProductData = {
    id_product: string;
    name_product: string;
    image_product: string;
    image_brand: string;
  };

  const history = useHistory();

  const [Brand, setBrand] = useState<Array<BrandData>>([]);

  const [Product, setProduct] = useState<Array<ProductData>>([]);

  const GetBrand = () => {
    API_GET_Brand_Popular()
      .then((res) => setBrand(res.data))
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const GetProduct = () => {
    API_GET_Product_New_Limit().then(res => setProduct(res.data)).catch(() => {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
        timer: 5000,
        timerProgressBar: true,
      });
    });
  }

  useEffect(() => {
    GetBrand();
    GetProduct();
  }, []);

  return (
    <>
      <Navbar />

      <div className="banner"></div>

      <div className="container">
        <div className="is-flex is-justify-content-center m-5">
          <div
            className="card has-background-primary"
            style={{
              top: "-120px",
              boxShadow: "0 0.5em 20px 1px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className="card-content is-flex is-justify-content-center is-flex-wrap-wrap"
              style={{ gap: "15px" }}
            >
              <div style={{ width: "450px" }}>
                <h3 className="title is-3 has-text-white">
                  Product Connect คืออะไร?
                </h3>
                <p className="has-text-white subtitle is-3">
                  แหล่งรวบรวมโมเดลสามมิติที่มีขนาดจริง และสามารถดาวน์โหลดโมเดลสามมิติได้ฟรี พร้อมทั้งมีเครื่องมือถอดราคา
                </p>
              </div>
              <div style={{ width: "450px" }}>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/XDgKKIjHogs"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-align-items-center p-2">
          <div className="column is-6 has-text-centered">
            <h1 className="title is-1">แบรนด์แนะนำ</h1>
            <p>แบรนด์สินค้าที่เข้าร่วมกับทาง</p>
            <p>Product connect</p>
          </div>
          <div className="column is-4">
            {Brand.length > 0 &&
              Brand.map((item, index) => (
                <CardBrand
                  id={item.id_brand}
                  key={index}
                  image={item.image_brand}
                  name={item.name_brand}
                />
              ))}
          </div>
          <div className="column">
            <a href="#" onClick={() => history.push("/brand")}>
              <h4 className="title is-4 has-text-centered">แบรนด์ทั้งหมด</h4>
              <h1 className="title is-1 has-text-centered">
                <FaAngleRight />
              </h1>
            </a>
          </div>
        </div>

        <div className="columns is-bg-red is-align-items-center">
          <div className="column is-6">
            <div className="is-flex is-justify-content-center is-align-items-center is-flex-wrap-wrap">
              {Product.length > 0 &&
                Product.map((item, index) => (
                  <ProductCard
                    key={index}
                    id={item.id_product}
                    name_product={item.name_product}
                    image_product={item.image_product}
                    image_brand={item.image_brand}
                  />
                ))}
                <div onClick={() => history.push('/product')} className="card-product is-justify-content-center">
                    <div className="overlay"></div>
                    <h2 className="title is-4">สินค้าทั้งหมด</h2>
                    <FaAngleRight className="title is-1" />
                </div>
            </div>
          </div>

          <div className="column is-6">
            <div className="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
              <h1 className="title is-1">สินค้ามาใหม่</h1>
              <p>สินค้าที่เพิ่มเข้ามาใหม่ใน </p>
              <p>Product Connect</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
