/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Navbar from "../../component/navbar";
import CardBrand from "../../component/brand";
import { FaSearch, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { API_Get_Brand } from "../../utils/API";
import Swal from "sweetalert2";
import Footer from "../../component/footer";

const Brand = () => {
  interface BrandStruct {
    id_brand: string;
    name_brand: string;
    image_brand: string;
  }

  const [Loading, setLoading] = useState<boolean>(true);

  const [Data, setData] = useState("");

  const [Page, setPage] = useState(1);

  const [Total, setTotal] = useState(0);

  const [Brand, setBrand] = useState<Array<BrandStruct>>([]);

  useEffect(() => {
    setLoading(true);
    const timeOutId = setTimeout(() => {
      Get_Brand();
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timeOutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  const Get_Brand = () => {
    API_Get_Brand(Data, Page)
      .then((res) => {
        setBrand(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const onPage = (id: number) => {
    if (id > 0 && id <= Math.ceil(Total / 50)) {
      setPage(id);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "90px", marginBottom: "150px", minHeight: '100vh' }}
      >
        <div className="field pt-5 ml-5 mr-5">
          <label htmlFor="search" className="label">
            ค้นหาแบรนด์
          </label>
          <div
            className={`control has-icons-left is-medium ${
              Loading ? "is-loading" : ""
            }`}
          >
            <input
              id="search"
              autoFocus
              onChange={(e) => setData(e.target.value)}
              value={Data}
              className="input is-rounded is-medium "
              type="text"
              placeholder="ค้นหาแบรนด์ ต่างๆ ตามชื่อ แบรนด์"
            />
            <span className="icon is-small is-left">
              <FaSearch />
            </span>
          </div>
        </div>
        <hr />
        <div
          className="is-flex is-aglin-items-center is-justify-content-center is-flex-wrap-wrap mt-5"
          style={{ gap: "10px" }}
        >
          {Loading ? (
                Array.from(Array(21), (_, index) => (
                    <div key={index} className="card-product-loading"></div>  
                ))
          ) : (
            Brand.map((item, index) => (
              <CardBrand
                key={index}
                id={item.id_brand}
                name_brand={item.name_brand}
                image_brand={item.image_brand}
              />
            ))
          )}
        </div>

        <nav
          className="pagination is-centered is-rounded m-2 mt-5"
          role="navigation"
          aria-label="pagination"
        >
          <a onClick={() => onPage(Page - 1)} className="pagination-previous">
            <FaAngleLeft />
          </a>
          <a onClick={() => onPage(Page - 1)} className="pagination-next">
            <FaAngleRight />
          </a>
          <ul className="pagination-list">
            {Array.from(Array(Math.ceil(Total / 50)), (v, i) => (
              <li key={i}>
                <a
                  onClick={() => onPage(i + 1)}
                  className={`pagination-link ${
                    i + 1 === Page ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${i + 1}`}
                >
                  {i + 1}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <Footer />
    </>
  );
};

export default Brand;
