import { createContext, FC, useRef, useState } from "react";

export const AuthContext = createContext<any>([]);

export const AuthProvider : FC = ({ children }) => {

    const [auth, setauth] = useState(false);
    const [data, setdata] = useState({
        firstname: '',
        lastname: ''
    });
    const search = useRef({
        name: '',
        work: '',
        category: '',
        kind: '',
    });
    
    const store = {
        Auth: [auth, setauth],
        Data: [data, setdata],
        Search: search.current,
    }

    return (
        <AuthContext.Provider value={store}>
            { children }
        </AuthContext.Provider>
    )
}