import React, { useState } from "react";
import { useEffect } from "react";
import { FaCogs, FaDownload, FaSketch } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import UpdateDetail from "../component/update_detail";
import { API_GET_Download_Extension } from "../utils/API";

const Extension = () => {

  type Update = {
    id_update: number
    file_update: string
    detail: string
    version: string
    updated_at: string
  };

  const [Data, setData] = useState<Array<Update>>([{
    id_update: 0,
    file_update: 'https://drive.google.com/u/3/uc?id=1gMWCgq2Kv6_N7aVdMh0k8nXFzCztb_oh&export=download',
    detail: '',
    version: '2.0.0',
    updated_at: '17-1-2022'
  }]);


  const GetURLDownload = () => {
    API_GET_Download_Extension().then(res => {
      setData(res.data);
    }).catch(() => {
      Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
          timer: 5000,
          timerProgressBar: true
      });
    });
  }

  useEffect(() => {
    // GetURLDownload();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container" style={{marginTop: '90px', minHeight: '100vh'}}>
        <div className="columns pt-5">
          <div className="column is-6">
            <img
              src="https://kh.product-connect.com/_nuxt/img/Product_Connect.aa5c901.webp"
              alt="NoImage"
            />
          </div>
          <div className="column is-6">
            <h1 className="title is-2 has-text-primary">Product Connect</h1>
            <p className="subtitle is-4">
              Extension เสริมบน Sketchup สำหรับดาวน์โหลด โมเดล BIM ฟรี
            </p>
            <button
              data-tip="ดาวน์โหลดไฟส์ Extension .rbz"
              className="button is-link is-large is-fullwidth"
              onClick={() => window.open(Data[0].file_update)}
            >
              <FaDownload className="mr-2" /> ดาวน์โหลด
            </button>
            <ReactTooltip place="bottom" effect="solid" />
            <p className="subtitle has-text-right is-6 mt-3">
              Version { Data[0].version } Lastupdate { Data[0].updated_at.substr(0,10) }
            </p>
            <div className="columns mt-5" style={{gap: '12px'}}>
              <div className="column card">
                <div className="card-content">
                  <h5 className="title is-5"><FaSketch /> SketchUp Compatibility</h5>
                  <p>
                    SketchUP 2021 SketchUp 2020 SketchUp 2019 Sketchup 2018
                    Sketchup 2017
                  </p>
                </div>
              </div>
              <div className="column card">
                <div className="card-content">
                  <h5 className="title is-5"><FaCogs className="mr-2" /> Operating System</h5>
                  <p>Windows</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h2 className="title is-3">รายละเอียดการอัพเดท</h2>
        {
          Data.map((item, index) => (
            <UpdateDetail key={index} version={item.version} detail={item.detail} />
          ))
        } */}
      </div>

      <Footer />
    </>
  );
};

export default Extension;
