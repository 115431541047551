import React, {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import Navbar from "../component/navbar";
import { FaLock, FaUser, FaFacebookSquare, FaGoogle } from "react-icons/fa";
import { API_Post_Login } from "../utils/API";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Swal from "sweetalert2";
import { AuthContext } from "../utils/Auth-Context";

const Login = () => {
  const history = useHistory();

  const context = useContext(AuthContext);

  const Data = useRef({
    username: "" as string,
    password: "" as string,
  });

  const [Remeberme, setRemeberme] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);

  const [Error, setError] = useState({
    username: false,
    passowrd: false,
  });

  const ChangeData = (event: ChangeEvent<HTMLInputElement>) => {
    let data = {
      ...Data.current,
      [event.target.id]: event.target.value,
    };
    Data.current = data;
  };

  const onSubmitData = () => {
    if (
      check_empty(Data.current.username, "username") &&
      check_empty(Data.current.password, "password")
    ) {
      setLoading(true);
      API_Post_Login(Data.current.username, Data.current.password)
        .then((res) => {
          Swal.fire({
            icon: res.status ? "success" : "error",
            title: res.status ? "สำเร็จ" : "เกิดข้อผิดพลาด",
            text: res.message,
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            if (res.status) {
              RemeberSave();
              localStorage.setItem("token", res.token.access_token);
              context.Auth = true;
              window.location.href = "/";
            }
            setLoading(false);
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
            timer: 5000,
            timerProgressBar: true,
          });
          setLoading(false);
        });
    }
  };

  const onSubmitFacebook = (data: any) => {
    console.log(data);
    console.log(data.id);
    console.log(data.name);
    console.log(data.email);
  };

  const onSubmitGoogle = (data: any) => {
    console.log(data.profileObj);
    console.log(data.profileObj.googleId);
    console.log(data.profileObj.email);
    console.log(data.profileObj.familyName);
    console.log(data.profileObj.givenName);
  };

  const RemeberSave = () => {
    if (Remeberme) {
      let data = JSON.stringify({
        username: Data.current.username,
        password: Data.current.password,
      });
      localStorage.setItem("remeberme", data);
    } else {
      localStorage.removeItem("remeberme");
    }
  };

  const CheckRemeberme = () => {
    if (localStorage.hasOwnProperty("remeberme")) {
      let data = JSON.parse(localStorage.getItem("remeberme") || "");
      setRemeberme(true);
      (document.getElementById("username") as HTMLInputElement).value =
        data["username"];
      (document.getElementById("password") as HTMLInputElement).value =
        data["password"];
      Data.current.username = data["username"];
      Data.current.password = data["password"];
    }
  };

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    event.key === "Enter" && onSubmitData();
  };

  const check_empty = (value: string, id: string) => {
    if (value !== "") {
      setError((preState) => ({ ...preState, [id]: false }));
      return true;
    } else {
      setError((preState) => ({ ...preState, [id]: true }));
      document.getElementById(id)?.focus();
      return false;
    }
  };

  useEffect(() => {
    CheckRemeberme();
  }, []);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "90px" }}>
        <div className="card mt-5" style={{ width: "350px", margin: "0 auto" }}>
          <div className="card-content">
            <h2 className="title is-2 has-text-centered">เข้าสู่ระบบ</h2>
            <div className="field">
              <label htmlFor="username" className="label">
                ชื่อผู้ใช้งาน
              </label>
              <div
                className={`control has-icons-left ${
                  Loading ? "is-loading" : ""
                }`}
              >
                <input
                  autoFocus
                  disabled={Loading}
                  id="username"
                  className={`input ${Error.username ? "is-danger" : ""}`}
                  onKeyPress={onEnter}
                  onChange={ChangeData}
                  type="text"
                  placeholder="อีเมล"
                />
                <span className="icon is-small is-left">
                  <FaUser />
                </span>
              </div>
              {Error.username && (
                <p className="help is-danger">
                  กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง
                </p>
              )}
            </div>
            <div className="field">
              <label htmlFor="password" className="label">
                รหัสผ่าน
              </label>
              <div
                className={`control has-icons-left ${
                  Loading ? "is-loading" : ""
                }`}
              >
                <input
                  disabled={Loading}
                  onKeyPress={onEnter}
                  onChange={ChangeData}
                  id="password"
                  className={`input ${Error.passowrd ? "is-danger" : ""}`}
                  type="password"
                  placeholder="รหัสผ่าน"
                />
                <span className="icon is-small is-left">
                  <FaLock />
                </span>
              </div>
              {Error.passowrd && (
                <p className="help is-danger">กรุณากรอกรหัสผ่านให้ถูกต้อง</p>
              )}
            </div>
            <label className="checkbox">
              <input
                disabled={Loading}
                checked={Remeberme}
                onChange={() => setRemeberme(!Remeberme)}
                type="checkbox"
              />{" "}
              จดจำ
            </label>

            <button
              disabled={Loading}
              onClick={() => onSubmitData()}
              className={`button is-primary is-fullwidth mt-3 mb-2 ${
                Loading ? "is-loading" : ""
              }`}
            >
              เข้าสู่ระบบ
            </button>
            {/* <FacebookLogin
              appId="508825343329027"
              cssClass="button is-fullwidth mt-2"
              fields="name,email"
              icon={<FaFacebookSquare style={{ marginRight: "5px" }} />}
              callback={onSubmitFacebook}
            />
            <GoogleLogin
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="button is-fullwidth mt-2"
                >
                  <FaGoogle style={{ marginRight: "5px" }} />
                  Login with Google
                </button>
              )}
              clientId="342070927336-5lddqvujihefeitdl979nc0795ifhht9.apps.googleusercontent.com"
              className="button"
              buttonText="gello"
              onSuccess={onSubmitGoogle}
            /> */}
            <button
              disabled={Loading}
              onClick={() => history.push("/register")}
              className={`button is-primary is-outlined is-fullwidth mt-2 ${
                Loading ? "is-loading" : ""
              }`}
            >
              สมัครสมาชิก
            </button>
            {/* <button
              disabled={Loading}
              onClick={() => history.push("/forgot")}
              className={`button is-primary is-light is-fullwidth mt-2 ${
                Loading ? "is-loading" : ""
              }`}
            >
              ลืมรหัสผ่าน
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
