import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AuthProvider } from '../utils/Auth-Context';
import Error404 from './Error404';
import Loading from './Loading';

const App = () => {

    return (
        <AuthProvider>
            <BrowserRouter>
                <Switch>
                    <Route exact path={['/', '/login', '/policy','/register', '/forgot',
                     '/product', '/product/:id', '/brand', '/oauth',
                      '/brand/:id','/extension']} component={Loading} />
                    <Route exact path="" component={Error404} />
                </Switch>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;