/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import { API_Post_Logout } from "../utils/API";
import { AuthContext } from "../utils/Auth-Context";

const Navbar = () => {
  const [Toggle, setToggle] = useState<boolean>(false);

  const context = useContext(AuthContext);

  const history = useHistory();

  const path = useLocation();

  const logout = (): void => {
    Swal.fire({
      icon: "warning",
      title: "คำเตือน",
      text: "คุณต้องการที่จะ ออกจากระบบ ใช่ หรือ ไม่?",
      timer: 5000,
      timerProgressBar: true,
      cancelButtonText: "ไม่",
      showCancelButton: true,
      confirmButtonText: "ใช่",
      showConfirmButton: true,
    }).then((res) => {
      if (res.value) {
        API_Post_Logout().finally(() => {
          context.Auth = false;
          localStorage.removeItem("token");
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: "ออกจากระบบเสร็จสิ้น",
            timer: 5000,
            timerProgressBar: true,
          }).then(() => {
            window.location.href = "/";
          });
        });
      }
    });
  };

  return (
    <nav
      className="navbar is-shadow is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" onClick={() => history.push('/')}>
            <b>Product Connect</b>
          </a>
          <a
            role="button"
            onClick={() => setToggle(!Toggle)}
            className={`navbar-burger ${Toggle ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarProductConnect"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          id="navbarProductConnect"
          className={`navbar-menu ${Toggle ? "is-active" : ""}`}
        >
          <div className="navbar-end">
            <a onClick={() => history.push('/product')} className={`navbar-item ${path.pathname === '/product' ? 'is-active' : ''}`}>สินค้าทั้งหมด</a>
            <a onClick={() => history.push('/brand')} className={`navbar-item ${path.pathname === '/brand' ? 'is-active' : ''}`}>แบรนด์</a>
            <a onClick={() => history.push('/extension')} className={`navbar-item ${path.pathname === '/extension' ? 'is-active' : ''}`}>ดาวน์โหลด</a>
            {
              context.Auth[0] ?
              <a onClick={() => logout()} className={`navbar-item`}>คุณ {context.Data[0].firstname} {context.Data[0].lastname}</a>
              :
              <a onClick={() => history.push('/login')} className={`navbar-item ${path.pathname === '/login' ? 'is-active' : ''}`}>เข้าสู่ระบบ</a>
            }
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
