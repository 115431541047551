import React from "react";
import { useHistory } from "react-router-dom";
import { API_Image_Brand } from "../utils/API";

type DataStruct = {
  id: string
  name: string
  image: string
};

const CardBrand : React.VFC<DataStruct> = ({name, image, id}) => {

  const history = useHistory();

  return (
      <div onClick={() => history.push('/brand/' + id)} className="card m-4 hover-card">
        <div className="card-content is-flex is-align-items-center" style={{ gap: "20px" }}>
          <img
            width="70px"
            src={API_Image_Brand(image)}
            alt="NoImage"
          />
          <p className="title is-4 mt-4">{name}</p>
        </div>
      </div>
  );
};

export default CardBrand;
