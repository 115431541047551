import React, { useRef, useState, ChangeEvent } from 'react';
import { FaKey } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { API_Post_Check, API_Post_Forgot } from '../../utils/API';

interface Struct {
    back: (id : number) => void
    code: (code : string) => void
    email: String
}

const Step1 = (props : Struct) => {

    const data = useRef('');

    const [Loading, setLoading] = useState(false);

    const [Error, setError] = useState(false);

    const check_empty = (value : string, id : string) : boolean => {
        if (value !== '') {
            setError(false);
            return true;
        } else {
            setError(true);
            document.getElementById(id)?.focus();
            return false;
        }
    }

    const ChangeData = (event : ChangeEvent<HTMLInputElement>) : void => {
        data.current = event.target.value;
    }
    
    const onsubmit = () : void => {
        if (
            check_empty(data.current, "code")
        ) {
            setLoading(true);
            API_Post_Check(props.email,data.current).then(res => {
                Swal.fire({
                    icon: res.status ? 'success' : 'error',
                    title: res.status ? 'สำเร็จ' : 'เกิดข้อผิดพลาด',
                    text: res.message,
                    timer: 5000,
                    timerProgressBar: true,
                }).then(() => {
                    if (res.status) {
                        props.code(data.current);
                        props.back(2);
                    }
                });
                setLoading(false);
            }).catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                    timer: 5000,
                    timerProgressBar: true,
                });
                setLoading(false);
            });
        }
    }

    const onSendCode = () => {
        setLoading(true);
        API_Post_Forgot(props.email).then(res => {
            Swal.fire({
                icon: res.status ? 'success' : 'error',
                title: res.status ? 'สำเร็จ' : 'เกิดข้อผิดพลาด',
                text: res.message,
                timer: 5000,
                timerProgressBar: true,
            });
            setLoading(false);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                timer: 5000,
                timerProgressBar: true,
            });
            setLoading(false);
        });
    }

    return (
            <div className="card mt-5" style={{width: '350px', margin: '0 auto'}}>
                <div className="card-content">
                    <h2 className="title is-2 has-text-centered">ตรวจสอบโค้ด</h2>
                    <div className="field">
                        <label htmlFor="code" className="label">โค้ดยืนยัน</label>
                        <div className={`control has-icons-left ${Loading ? 'is-loading' : ''}`}>
                            <input disabled={Loading} onChange={ChangeData} id="code" className={`input ${Error ? 'is-danger' : ''}`} type="text" placeholder="โค้ดที่ส่งไปใน อีเมล ของท่าน" />
                            <span className="icon is-small is-left">
                                <FaKey />
                            </span>
                        </div>
                        {
                            Error && <p className="help is-danger">กรุณากรอกโค้ดยืนยันให้ถูกต้อง</p>
                        }
                    </div>

                    <button disabled={Loading} onClick={() => onsubmit()} className="button is-primary is-fullwidth mt-3 mb-2">ตรวจสอบ</button>
                    <button disabled={Loading} onClick={() => onSendCode()} className="button is-primary is-outlined is-fullwidth mb-2">ส่งอีกครั้ง</button>
                    <button disabled={Loading} onClick={() => props.back(0)} className="button is-primary is-outlined is-fullwidth mb-2">กลับ</button>
                    
                </div>
            </div>
    )
}

export default Step1;