import React, { useEffect, useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { API_Get_Brand, API_Image_Brand } from '../../utils/API';

interface Struct {
    name: string
    data: (id : string, name : string) => void
}

const Brand = (props : Struct) => {

    interface BrandStruct {
        id_brand: string,
        name_brand: string,
        image_brand: string
    }

    const [Status, setStatus] = useState(false);

    const [Brands, setBrands] = useState<Array<BrandStruct>>([]);

    const ChangeStatus = () => {
        setStatus(!Status);
    }

    const Get_Brand = () => {
        API_Get_Brand("", 1).then(res => {
            setBrands(res.data);
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้',
                timer: 5000,
                timerProgressBar: true
            });
        });
    }

    const onClickBrand = (id : string, name : string) => {
        props.data(id, name);
        ChangeStatus();
    }

    useEffect(() => {
        Get_Brand();
    }, []);

    return (
        <>
        <button onClick={() => ChangeStatus()} className="button is-primary is-rounded" style={{marginRight: 'auto'}}>{props.name ? props.name : 'เลือกแบรนด์'}</button>
        <div className={`modal ${Status ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={() => ChangeStatus()}  />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">เลือกแบรนด์</p>
                    <button className="delete" onClick={() => ChangeStatus()} aria-label="close" />
                </header>
                <section className="modal-card-body">
                    <div className="is-flex is-justify-content-center is-align-items-center" style={{gap: '10px'}}>
                        {
                            Brands.map((item, index) => (
                                <div key={index} onClick={() => onClickBrand(item.id_brand, item.name_brand)} className="button is-medium" style={{height: '70px'}}>
                                        <img width="50px" src={API_Image_Brand(item.image_brand)} alt="NoImage" />
                                </div>
                            ))
                        }
                                <div onClick={() => onClickBrand('', '')} className="button is-medium" style={{height: '70px', width: '70px'}}>
                                    <FaTimesCircle className="has-text-danger" style={{fontSize: '28px'}} />
                                </div>
                    </div>
                </section>
            </div>
        </div>
        </>
    );
}

export default Brand;