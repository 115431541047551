import React from 'react';
import { useHistory } from 'react-router';
import { API_Image_Brand } from '../utils/API';

interface Data {
    id: string,
    name_brand: string,
    image_brand: string
};

const Brand = (props : Data) => {

    const history = useHistory();


    return (
        
        <div onClick={() => history.push('/brand/' + props.id)} className="card-product">
            <div className="overlay"></div>
            <div>
                <img className="card-image-product" src={API_Image_Brand(props.image_brand)} alt="NoImage" />
            </div>
            <h5>{props.name_brand}</h5>
        </div>
    );
}

export default Brand;