/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import Navbar from "../../component/navbar";
import Product from "../../component/product";
import {
  FaSearch,
  FaBuilding,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import Brand from "../../component/search/brand";
import Swal from "sweetalert2";
import {
  API_Get_Category,
  API_Get_Work,
  API_Get_Kind,
  API_Get_Search,
} from "../../utils/API";
import { AuthContext } from "../../utils/Auth-Context";
import Footer from "../../component/footer";

const Search_product = () => {
  const context = useContext(AuthContext);

  interface ProductStruct {
    id_product: string;
    name_product: string;
    image_product: string;
    image_brand: string;
  }

  interface WorkStruct {
    id_work: string;
    name_work: string;
  }

  interface CategoryStruct {
    id_category: string;
    id_work: string;
    name_category: string;
  }

  interface KindStruct {
    id_kind: string;
    id_category: string;
    name_kind: string;
  }

  const [Loading, setLoading] = useState<boolean>(true);

  const [Data, setData] = useState({
    name: context.Search.name,
    work: context.Search.work,
    category: context.Search.category,
    kind: context.Search.kind,
    brand: "",
    namebrand: "",
    page: 1,
  });

  const [Total, setTotal] = useState(0);

  const [Products, setProducts] = useState<Array<ProductStruct>>([]);

  const [Work, setWork] = useState<Array<WorkStruct>>([]);
  const [Category, setCategory] = useState<Array<CategoryStruct>>([]);
  const [Kind, setKind] = useState<Array<KindStruct>>([]);

  const ChangeIDBrand = (id: string, name: string) => {
    setData((preState) => ({
      ...preState,
      brand: id,
      namebrand: name,
    }));
  };

  const Get_Product = () => {
    API_Get_Search(
      Data.name,
      Data.work,
      Data.category,
      Data.kind,
      Data.brand,
      Data.page
    )
      .then((res) => {
        setProducts(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Get_Work = () => {
    API_Get_Work()
      .then((res) => {
        setWork(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Get_Category = (id: string) => {
    API_Get_Category(id)
      .then((res) => {
        setCategory(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const Get_Kind = (id: string) => {
    API_Get_Kind(id)
      .then((res) => {
        setKind(res.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
      });
  };

  const ChangeData = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target.value !== "") {
      if (event.target.id === "work") {
        Get_Category(event.target.value);
      } else if (event.target.id === "category") {
        Get_Kind(event.target.value);
      }
      setData((prevState) => ({
        ...prevState,
        [event.target.id]: event.target.value,
      }));
    } else {
      switch (event.target.id) {
        case "work":
          setData((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
            category: "",
            kind: "",
          }));
          break;
        case "category":
          setData((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
            kind: "",
          }));
          break;
        default:
          setData((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
          }));
          break;
      }
    }
    let data = {
      ...context.Search,
      [event.target.id]: event.target.value,
    };
    context.Search = data;
  };

  const onPage = (id: number) => {
    if (id > 0 && id <= Math.ceil(Total / 50)) {
      setData((prevState) => ({
        ...prevState,
        page: id,
      }));
    }
  };

  useEffect(() => {
    Get_Work();
    if (Data.category !== "") {
      Get_Category(Data.category);
    }
    if (Data.kind !== "") {
      Get_Kind(Data.kind);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const timeOutId = setTimeout(() => {
      Get_Product();
      setLoading(false);
      setProducts([]);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [Data]);
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "90px", marginBottom: "50px", minHeight: "100vh" }}
      >
        <div className="field pt-5 ml-5 mr-5">
          <label className="label">ค้นหาสินค้า</label>
          <div
            className={`control has-icons-left is-medium ${
              Loading ? "is-loading" : ""
            }`}
          >
            <input
              value={Data.name}
              id="name"
              autoFocus
              onChange={ChangeData}
              className="input is-rounded is-medium"
              type="text"
              placeholder="ค้นหาสินค้า ประเภทสินค้า หมวดงานสินค้า แบรนด"
            />
            <span className="icon is-small is-left">
              <FaSearch />
            </span>
          </div>
        </div>
        <div className="field-body m-2">
          <div className="field">
            <label className="work">หมวดงาน</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select value={Data.work} id="work" onChange={ChangeData}>
                  <option value="">ทั้งหมด</option>
                  {Work.map((item, index) => (
                    <option key={index} value={item.id_work}>
                      {item.name_work}
                    </option>
                  ))}
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="category">ประเภท</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select
                  value={Data.category}
                  id="category"
                  onChange={ChangeData}
                  disabled={Data.work.length === 0}
                >
                  <option value="">ทั้งหมด</option>
                  {Category.map((item, index) => (
                    <option key={index} value={item.id_category}>
                      {item.name_category}
                    </option>
                  ))}
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="kind">หมวดหมู่</label>
            <div className="control has-icons-left">
              <div className="select is-fullwidth is-rounded">
                <select
                  value={Data.kind}
                  id="kind"
                  onChange={ChangeData}
                  disabled={Data.category.length === 0}
                >
                  <option value="">ทั้งหมด</option>
                  {Kind.map((item, index) => (
                    <option key={index} value={item.id_kind}>
                      {item.name_kind}
                    </option>
                  ))}
                </select>
                <span className="icon is-small is-left">
                  <FaBuilding />
                </span>
              </div>
            </div>
          </div>

        </div>
        <div
          className="is-flex is-justify-content-flex-end is-align-items-center m-2"
          style={{ gap: "10px" }}
        >
          <Brand name={Data.namebrand} data={ChangeIDBrand} />
          {/* <button className="button is-primary is-light is-rounded is-small"><FaFire style={{marginRight: '3px'}} /> ยอดนิยม</button>
                    <button className="button is-primary is-light is-rounded is-small"><FaStar style={{marginRight: '3px'}} /> ใหม่</button> */}
        </div>
        <hr style={{ marginTop: "10px" }} />
        <div
          className="is-flex is-aglin-items-center is-justify-content-center is-flex-wrap-wrap mt-5"
          style={{ gap: "10px" }}
        >
          {Loading ? (
            <>
              {Array.from(Array(21), (_,index) => (
                <div key={index} className="card-product-loading"></div>
              ))}
            </>
          ) : (
            <>
              {Products.map((item, index) => (
                <Product
                  key={index}
                  id={item.id_product}
                  name_product={item.name_product}
                  image_product={item.image_product}
                  image_brand={item.image_brand}
                />
              ))}
            </>
          )}
        </div>
        <nav
          className="pagination is-centered is-rounded m-2 mt-5"
          role="navigation"
          aria-label="pagination"
        >
          <a
            onClick={() => onPage(Data.page - 1)}
            className="pagination-previous"
          >
            <FaAngleLeft />
          </a>
          <a onClick={() => onPage(Data.page + 1)} className="pagination-next">
            <FaAngleRight />
          </a>
          <ul className="pagination-list">
            {Array.from(Array(Math.ceil(Total / 50)), (_, i) => (
              <li key={i}>
                <a
                  onClick={() => onPage(i + 1)}
                  className={`pagination-link ${
                    i + 1 === Data.page ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${i + 1}`}
                >
                  {i + 1}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <Footer />
    </>
  );
};

export default Search_product;
