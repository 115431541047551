import axios from "axios";

// const API = "http://localhost:5421/api/v1/";
// const API = "http://139.59.98.79:5420/api/v1/";
const API = "https://api.product-connect.com/api/v1/";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error.response);
  }
);

export const API_GET_Country = () => {
  return axios
    .get("https://ipapi.co/country_name/")
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_GET_Download_Extension = () => {
  return axios
    .get(API + "update/")
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_GET_Product_New_Limit = () => {
  return axios
    .get(API + "product/new")
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_GET_Work_All_Brand = (id: string) => {
  return axios
    .get(API + "work/brand/" + id)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_GET_Detail_Product = (id: string) => {
  return axios
    .get(API + "detail/" + id)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_GET_Brand_Popular = () => {
  return axios
    .get(API + "brand/popular")
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Brand = (name: string, page: number) => {
  return axios
    .get(`${API}brand/search?name=${name}&page=${page}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Brand_ID = (id: string) => {
  return axios
    .get(`${API}brand/find/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Product_ID = (id: string) => {
  return axios
    .get(`${API}product/find/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Job = () => {
  return axios
    .get(`${API}job/`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Work = () => {
  return axios
    .get(`${API}work/`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Category = (id: string) => {
  return axios
    .get(`${API}category/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Kind = (id: string) => {
  return axios
    .get(`${API}kind/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Get_Search = (
  name: string,
  work: string,
  category: string,
  kind: string,
  brand: string,
  page: number
) => {
  return axios
    .get(
      `${API}product/search?name=${name}&work=${work}&category=${category}&kind=${kind}&brand=${brand}&page=${page}`
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Forgot = (username: String) => {
  return axios
    .post(API + "member/forgot", {
      username: username,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Check = (Username: String, Code: string) => {
  return axios
    .post(API + "member/code", {
      username: Username,
      code: Code,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Rest = (
  Username: String,
  Code: String,
  password: String
) => {
  return axios
    .post(API + "member/reset", {
      username: Username,
      code: Code,
      password: password,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Register = (
  username: string,
  password: string,
  firstname: string,
  lastname: string,
  gender: string,
  job: string,
  tel: string,
  country: string,
) => {
  return axios
    .post(`${API}member/`, {
      username: username,
      password: password,
      firstname: firstname,
      lastname: lastname,
      gender: gender === "1" ? true : false,
      id_job: job,
      tel: tel,
      country: country,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Login = (username: string, password: string) => {
  return axios
    .post(`${API}auth/login`, {
      username: username,
      password: password,
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Logout = () => {
  return axios
    .post(
      `${API}auth/logout`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Post_Me = () => {
  return axios
    .post(
      `${API}auth/me`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Image_Brand = (url: string) => {
  return API + "download/brand/img/" + url;
};

export const API_Image_Product = (url: string) => {
  return API + "download/product/img/" + url;
};

export const API_GLTF_Product = (url: string) => {
  return API + "download/product/gltf/" + url;
};

export const API_Download_SKP = (name: string, id: string) => {
  return axios
    .get(API + "download/product/skp/" + name + "?id=" + id, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const API_Brand_Kind = (id: string) => {
  return axios
    .get(API + "work/brand/" + id)
    .then((res) => res.data)
    .catch((err) => err.response);
};
