/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { FaCube, FaDownload, FaEye, FaImage, FaShare } from "react-icons/fa";
import Navbar from "../../component/navbar";
import "@google/model-viewer";
import { useHistory, useParams } from "react-router";
import {
  API_Get_Product_ID,
  API_Image_Brand,
  API_Image_Product,
  API_GLTF_Product,
  API_Download_SKP,
  API_GET_Detail_Product,
} from "../../utils/API";
import Swal from "sweetalert2";
import { AuthContext } from "../../utils/Auth-Context";
import Footer from "../../component/footer";

const Product_id = () => {
  const [Status, setStatus] = useState(false);

  const context = useContext(AuthContext);

  const ProductStruct = {
    id_product: "",
    id_work: "",
    id_category: "",
    id_kind: "",
    id_brand: "",
    name_work: "",
    name_brand: "",
    productconnect_id: "",
    name_category: "",
    name_kind: "",
    name_product: "",
    price_product: 0,
    skp_product: "",
    gltf_product: "",
    image_product: "",
    image_brand: "",
    view_product: 0,
    download_product: 0,
    link: "",
  };

  interface DetailStruct {
    header: string;
    detail: string;
  }

  const param = useParams<{
    id: string;
  }>();

  const [Product, setProduct] = useState<typeof ProductStruct>(ProductStruct);
  const [Detail, setDetail] = useState<Array<DetailStruct>>([]);

  const history = useHistory();

  const DownloadModel = () => {
    if (localStorage.hasOwnProperty("token")) {
      API_Download_SKP(Product.skp_product, Product.id_product).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.download = Product.name_product + ".skp";
        link.click();
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "คำเตือน",
        text: "กรุณาเข้าสู่ระบบก่อน",
        timer: 5000,
        timerProgressBar: true,
      }).then(() => {
        history.push("/login");
      });
    }
  };

  const Get_Product = () => {
    API_Get_Product_ID(param.id)
      .then((res) => {
        setProduct(res.data[0]);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
        history.push("/");
      });
  };

  const Get_Detail_product = () => {
    API_GET_Detail_Product(param.id)
      .then((res) => {
        setDetail(sortdetail(res.data));
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้",
          timer: 5000,
          timerProgressBar: true,
        });
        history.push("/");
      });
  };

  const ClickCategory = (work: string, category?: string, kind?: string) => {
    let data = {
      name: '',
      work: work || "",
      category: category || "",
      kind: kind || "",
    };
    context.Search = data;

    history.push("/product");
    console.log(context.Search);
  };

  const Share = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href,
      "_blank",
      "width=700,height=400"
    );
  };

   const sortdetail = (data: Array<DetailStruct>) => {
        if(data[0].header.substring(0, 4).indexOf('.') > 0) {
            data = data.sort((a, b) => {
                if(parseInt(a.header.split(".")[0]) > parseInt(b.header.split(".")[0])) return 1;
                if(parseInt(a.header.split(".")[0]) < parseInt(b.header.split(".")[0])) return -1;
                return 0;
            });
            for(let index in data) {
                data[index].header = data[index].header.split(".")[1];
            }
        } else if (data[0].header.substring(0,4).match(/\d+/g)) {
            data = data.sort((a, b) => {
                if(parseInt(a.header.split(" ")[0]) > parseInt(b.header.split(" ")[0])) return 1;
                if(parseInt(a.header.split(" ")[0]) < parseInt(b.header.split(" ")[0])) return -1;
                return 0;
            });
            for(let index in data) {
                data[index].header = data[index].header.replace(/\d+/g, '');
            }
        }
        return data;
    }


  useEffect(() => {
    Get_Product();
    Get_Detail_product();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: "90px", minHeight: "100vh" }}
      >
        <div className="columns m-1">
          <div className="column">
            <nav
              className="breadcrumb"
              aria-label="breadcrumbs"
              style={{ fontSize: "14px" }}
            >
              <ul>
                <li onClick={() => ClickCategory(Product.id_work)}>
                  <a href="#">{Product.name_work}</a>
                </li>
                <li
                  onClick={() =>
                    ClickCategory(Product.id_work, Product.id_category)
                  }
                >
                  <a href="#">{Product.name_category}</a>
                </li>
                <li
                  onClick={() =>
                    ClickCategory(
                      Product.id_work,
                      Product.id_category,
                      Product.id_kind
                    )
                  }
                >
                  <a href="#">{Product.name_kind}</a>
                </li>
                <li className="is-active">
                  <a href="#" aria-current="page">
                    {Product.name_product}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="columns m-1">
          <div className="column card is-5">
            <div
              className="is-flex is-flex-direction-column is-justify-content-space-between is-fullheight"
              style={{ height: "100%" }}
            >
              <div>
                {Status ? (
                  <>
                    {/* @ts-ignore */}
                    <model-viewer
                      style={{ width: "100%", height: "450px" }}
                      src={API_GLTF_Product(Product.gltf_product)}
                      alt="3D Model"
                      auto-rotate
                      camera-controls
                    />
                  </>
                ) : (
                  Product.image_product !== "" && (
                    <figure className="has-text-centered">
                      <img
                        width="100%"
                        src={API_Image_Product(Product.image_product)}
                        alt="NoImage"
                      />
                    </figure>
                  )
                )}
              </div>
              <div>
                <button
                  onClick={() => setStatus(!Status)}
                  className="button is-outlined is-primary is-fullwidth "
                >
                  {Status ? (
                    <FaImage className="mr-2" />
                  ) : (
                    <FaCube className="mr-2" />
                  )}{" "}
                  {Status ? "Image Viewer" : "3D Viewer"}
                </button>
                <button
                  onClick={() => Share()}
                  className="button is-success is-outlined is-fullwidth mt-2"
                >
                  <FaShare className="mr-2" /> แชร์
                </button>
              </div>
            </div>
          </div>
          <div className="column pb-0  pt-0">
            <div className="columns is-align-items-center">
              <div className="column is-3 has-text-centered">
                {Product.image_brand !== "" && (
                  <img
                    src={API_Image_Brand(Product.image_brand)}
                    width="100px"
                    alt="NoImage"
                  />
                )}
              </div>
              <div className="column">
                <h4 className="title is-4 mb-0">{ Product.name_brand }</h4>
                {/* <p>
                  มีสินค้าทั้งหมด <b className="has-text-primary"></b> ชิ้น
                </p> */}
              </div>
              <div className="column">
                <button
                  onClick={() => history.push("/brand/" + Product.id_brand)}
                  className="button is-link"
                >
                  ไปหน้าแบรนด์
                </button>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <h4 className="subtitle is-4 mb-1">{Product.name_product}</h4>
                <div style={{ height: "250px", overflowY: "scroll" }}>
                  <table className="table is-fullwidth mb-2">
                    <tbody>
                      {Detail.length > 0 ? (
                        Detail.map((item, index) => (
                          <tr key={index}>
                            <td>{item.header}</td>
                            <td>{item.detail}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="has-text-centered" colSpan={2}>
                            ไม่มีรายละเอียด
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="is-flex is-flex-wrap-wrap">
                  <h5 className="title is-5 mr-5">
                    <FaEye className="mr-1" /> จำนวนสินคนเข้าชม{" "}
                    <b className="has-text-primary">{Product.view_product}</b>{" "}
                    คน
                  </h5>
                  <h5 className="title is-5">
                    <FaDownload className="mr-1" /> ยอดดาวน์โหลด{" "}
                    <b className="has-text-primary">
                      {Product.download_product}
                    </b>{" "}
                    ครั้ง
                  </h5>
                </div>
                <h3 className="title is-2 has-text-primary mt-3">
                  ราคา ฿{Product.price_product}
                </h3>
                <button
                  onClick={() => window.open(Product.link)}
                  className="button is-primary is-outlined is-rounded is-fullwidth is-medium mt-3"
                >
                  สั่งซื้อสินค้า
                </button>
                <h4 className="title is-4 mt-5">ดาวน์โหลด</h4>
                <div className="is-flex">
                  {Product.skp_product !== "" && (
                    <button
                      onClick={() => DownloadModel()}
                      className="button mr-2"
                    >
                      SKP
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Product_id;
